function caps(string, type = 'first') {
    switch (type) {
        case 'first':
            return string.charAt(0).toUpperCase() + string.slice(1);
        case 'allStarting':
            return string.split(' ').map(word => caps(word)).join(' ');
        case 'allcaps':
            return string.toUpperCase();
        case 'nocaps':
            return string.toLowerCase();
        case 'camel':
            return string.replace(/\b\w/g, l => l.toUpperCase());
        default:
            return string;
    }
}
export default caps;