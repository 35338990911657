import { useTranslation } from "react-i18next";
import i18n from "i18next";

import Carousels from "./DealsComponents/Carousels";
/* import Cards from "./DealsComponents/Cards"; */
import caps from "../utils/capital";
const Deals = () => {
  const { t } = useTranslation();
  const deals = require(`../data/${i18n.language ? i18n.language : "dk"}/deals.json`);
  return (
    <>
      <h1 className="text-center">{caps(t("deals"))}</h1>
      <Carousels items={deals} />
      {/* {<Cards items={deals} />} */}
    </>
  );
};

export default Deals;
