import { useTranslation } from "react-i18next";
import { Button, Container } from "react-bootstrap";
import caps from "../utils/capital";
const DropLinks = () => {
  const { t } = useTranslation();
  return (
    <Container className="dropLinkContainer">
      <Button
        style={{ backgroundColor: "#fe319e", borderColor: "black", borderWidth: "2px" }}
        href="#pizza-menu"
      >
        {caps(t("pizza"))}
      </Button>
      <Button
        style={{ backgroundColor: "#fe319e", borderColor: "black", borderWidth: "2px" }}
        href="#durum-menu"
      >
        {caps(t("durum"))}
      </Button>
      <Button
        style={{ backgroundColor: "#fe319e", borderColor: "black", borderWidth: "2px" }}
        href="#burger-menu"
      >
        {caps(t("burger"))}
      </Button>
      <Button
        style={{ backgroundColor: "#fe319e", borderColor: "black", borderWidth: "2px" }}
        href="#breadstick-menu"
      >
        {caps(t("breadSticks"))}
      </Button>
      <Button
        style={{ backgroundColor: "#fe319e", borderColor: "black", borderWidth: "2px" }}
        href="#kids-menu"
      >
        {caps(t("smallMenu"))}
      </Button>
      <Button
        style={{ backgroundColor: "#fe319e", borderColor: "black", borderWidth: "2px" }}
        href="#garlicbread-menu"
      >
        {caps(t("garlicBread"))}
      </Button>
      <Button
        style={{ backgroundColor: "#fe319e", borderColor: "black", borderWidth: "2px" }}
        href="#extras-menu"
      >
        {caps(t("extra"))}
      </Button>
    </Container>
  );
};

export default DropLinks;
