import React from "react";
const TrustBox = () => {
  // Create a reference to the <div> element which will represent the TrustBox
  const ref = React.useRef(null);
  React.useEffect(() => {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
    // When it is, it will automatically load the TrustBox.
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);
  return (
    <div
      class="trustpilot-widget"
      data-locale="en-US"
      data-template-id="56278e9abfbbba0bdcd568bc"
      data-businessunit-id="660d7bf88b3e70a367275d92"
      data-style-height="52px"
      data-style-width="100%"
      style={{ margin: "0.5em" }}
    >
      <a href="https://www.trustpilot.com/review/lapizzafactory.dk" target="_blank" rel="noopener noreferrer">
        Trustpilot
      </a>
    </div>
  );
};
export default TrustBox;
