import { useTranslation } from "react-i18next";
import i18n from "i18next";

import PizzaMenu from "./MenuSections/PizzaMenu";
import SmallMenu from "./MenuSections/SmallMenu";
import DropLinks from "./DropLinks";
import caps from "../utils/capital";
import TrustBox from "./TrustBox";

const Menu = () => {
  const { t } = useTranslation();
  const pizzas = require(`../data/${i18n.language ? i18n.language : "dk"}/pizzas.json`);
  return (
    <div className="menu">
      <h1>{caps(t("menu"))}</h1>
      <DropLinks />
      <div style={{ padding: "0px" }} id="pizza-menu">
        <PizzaMenu title={caps(t("pizzas"))} pizzas={pizzas.filter((item) => item.type === "pizza")} />
      </div>
      <div id="durum-menu" className="row mx-auto" style={{ maxWidth: "900px" }}>
        <div className="col-md-6">
          <SmallMenu title={caps(t("durum"))} items={pizzas.filter((item) => item.type === "durum")} />
        </div>
        <div id="burger-menu" className="col-md-6">
          <SmallMenu title={caps(t("burgers"))} items={pizzas.filter((item) => item.type === "burger")} />
        </div>
        <div id="breadstick-menu" className="col-md-12">
          <PizzaMenu
            title={caps(t("breadSticks"))}
            pizzas={pizzas.filter((item) => item.type === "breadSticks")}
          />
        </div>
        <div id="kids-menu" className="col-md-6">
          <SmallMenu title={caps(t("smallMenu"))} items={pizzas.filter((item) => item.type === "kids")} />
        </div>
        <div id="garlicbread-menu" className="col-md-6">
          <SmallMenu
            title={caps(t("garlicBread"))}
            items={pizzas.filter((item) => item.type === "garlicBread")}
          />
        </div>
        <div id="extras-menu" className="col-md-12">
          <SmallMenu title={caps(t("extra"))} items={pizzas.filter((item) => item.type === "extra")} />
        </div>
      </div>

      <TrustBox />
    </div>
  );
};

export default Menu;
