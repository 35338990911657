import { Table, Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import caps from "../../utils/capital";

const SmallMenu = ({ title, items }) => {
  const { t } = useTranslation();
  return (
    <Table border={0} className="bg-table-transparent">
      <thead>
        <tr>
          <th
            colSpan={2}
            style={{
              background: `url('/images/brush.svg')`,
              backgroundSize: "75% 90%",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
          >
            <h2>{title}</h2>
          </th>
          <th>{caps(t("price"))}</th>
        </tr>
      </thead>
      <tbody>
        {items.map((item) => (
          <tr key={item.name}>
            <td className="fit align-middle">
              {item.image ? (
                <Image
                  roundedCircle
                  src={process.env.PUBLIC_URL + item.image}
                  alt={item.name}
                  style={{ maxHeight: "5em" }}
                />
              ) : (
                ""
              )}
            </td>
            <td>
              <div className="text-start">
                <h3>{item.name}</h3>
                <p>{item.ingredients}</p>
              </div>
            </td>
            <td>{item.price ? item.price + t("dkk") : ""}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default SmallMenu;
