import { Container, Carousel, Image } from "react-bootstrap";

import caps from "../../utils/capital";

const Cards = ({ items }) => {
  return (
    <>
      <Container className="mx-auto" style={{ maxWidth: "900px" }}>
        <Carousel variant="dark" indicators={false} controls={false}>
          {items.map((item) => (
            <Carousel.Item key={caps(item.title)}>
              <Image src={process.env.PUBLIC_URL + item.image} className="d-block w-100" />
            </Carousel.Item>
          ))}
        </Carousel>
      </Container>
    </>
  );
};

export default Cards;
