import React from 'react';
import ReactDOM from 'react-dom/client';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

import './index.scss';
import App from './App';

i18n
.use(initReactI18next)
.use(LanguageDetector)
.use(HttpApi)
.init({
  supportedLngs: ['en', 'dk', "de"],
  fallbackLng: 'en',
  defaultLanguage: 'en',
  detection: {
    order: ['localStorage'],
    caches: ['localStorage']
  },
  backend: {
    loadPath: '/static/locales/{{lng}}/translation.json'
  },
  react: {
    useSuspense: false
  },
  load: 'languageOnly'
});


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);