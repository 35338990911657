const Footer = () => {
  return (
    <footer className="footer">
      <p>&copy; {new Date().getFullYear()} La Pizza Factory </p>
      <p>
        Address:{" "}
        <a href="https://www.google.com/maps?q=Englandsgade+35A,+6700+Esbjerg">
          Englandsgade 35A, 6700 Esbjerg
        </a>
      </p>
      <p>
        Telephone: <a href="tel:+4574404060">+45 74 40 40 60</a>
      </p>
      <p>
        Email: <a href="mailto:info@lapizzafactory.dk">info@lapizzafactory.dk</a>
      </p>
    </footer>
  );
};

export default Footer;
