import { Table, Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import caps from "../../utils/capital";

const PizzaMenu = ({ title, pizzas }) => {
  const { t } = useTranslation();
  return (
    <Table bordered={0} style={{ maxWidth: "900px" }} className="mx-auto bg-table-transparent">
      <thead>
        <tr>
          <th
            colSpan={2}
            style={{
              background: `url('/images/brush.svg')`,
              backgroundSize: "75% 90%",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
          >
            <h2>{title}</h2>
          </th>
          <th className="p-md--02em">{caps(t("small")) + ' 9"'}</th>
          <th className="p-md--02em">{caps(t("medium")) + ' 13"'}</th>
          <th className="p-md--02em">{caps(t("large")) + ' 20"'}</th>
        </tr>
      </thead>
      <tbody>
        {pizzas.map((pizza) => (
          <tr key={pizza.name}>
            <td className="fit align-middle p-sm-0" style={{ padding: "0px" }}>
              {pizza.image ? (
                <Image
                  roundedCircle
                  src={process.env.PUBLIC_URL + pizza.image}
                  alt={pizza.name}
                  className="mh-md-3em"
                />
              ) : (
                ""
              )}
            </td>
            <td>
              <div className="text-start">
                <h4>{pizza.name}</h4>
                <p>{pizza.ingredients}</p>
              </div>
            </td>
            <td className="p-md--02em">{pizza.small ? pizza.small + t("dkk") : ""}</td>
            <td className="p-md--02em">{pizza.medium ? pizza.medium + t("dkk") : ""}</td>
            <td className="p-md--02em">{pizza.large ? pizza.large + t("dkk") : ""}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default PizzaMenu;
