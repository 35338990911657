import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import "./App.css";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import MenuPage from "./pages/MenuPage";
import Contact from "./pages/Contact";
import OrderButton from "./components/OrderButton";
import LpfNavbar from "./components/Navbar";

function App() {
  return (
    <Router>
      <div className="App">
        <LpfNavbar />
        <div
          className="content"
          style={{ background: `url('/images/pizza-background.svg')`, backgroundSize: "100% auto" }}
        >
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/menu" element={<MenuPage />} />
            <Route exact path="/contact" element={<Contact />} />
          </Routes>
        </div>
        <Footer />
        <OrderButton />
      </div>
    </Router>
  );
}

export default App;
