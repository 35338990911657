import { Container } from "react-bootstrap";
import Menu from "../components/Menu";
import Deals from "../components/Deals";

const Home = () => {
  return (
    <div className="home">
      <Container className=".p-3">
        <Deals />
        <Menu />
      </Container>
    </div>
  );
};

export default Home;
