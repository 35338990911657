import React from "react";
import { Container } from "react-bootstrap";
import TrustBox from "../components/TrustBox";
/* import GoogleMapReact from "google-map-react"; */

const Contact = () => {
  /*   const defaultProps = {
    center: {
      lat: 10.99835602,
      lng: 77.01502627,
    },
    zoom: 11,
  }; */
  return (
    <div className="contact">
      <Container className=".p-3">
        <TrustBox />
        {/* 
        <div style={{ height: "100vh", width: "100%" }}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: "" }}
            defaultCenter={defaultProps.center}
            defaultZoom={defaultProps.zoom}
          ></GoogleMapReact>
        </div> */}
      </Container>
    </div>
  );
};

export default Contact;
