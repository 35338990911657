import { useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import { Nav, Image, Dropdown, Button, Offcanvas } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { changeLanguage } from "i18next";
import { useTranslation } from "react-i18next";
import caps from "../utils/capital";

import packageJson from "../../package.json";

const languages = [
  {
    code: "en",
    name: "English",
    countryCode: "gb",
  },
  {
    code: "dk",
    name: "Dansk",
    countryCode: "dk",
  },
  {
    code: "de",
    name: "Deutsch",
    countryCode: "de",
  },
];

const LpfNavbar = () => {
  const { t } = useTranslation();

  //used for offcanvas menu
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  /*   const handleShow = () => setShow(true); */
  const toggleOffcanvas = () => setShow(!show);

  return (
    <>
      {/* Navbar for desktop and tablet screens */}
      <Navbar className="px-3 d-none d-md-flex" bg="light" variant="light" expand="md">
        <Navbar.Brand className="mx-auto text-lg-left" as={NavLink} to="/">
          <Image src="logo.png" />
        </Navbar.Brand>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Item>
              <NavLink className="nav-link" to="/">
                <Button style={{ backgroundColor: "#fe319e", borderColor: "black", borderWidth: "2px" }}>
                  {caps(t("home"))}
                </Button>
              </NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink className="nav-link" to="/menu">
                <Button style={{ backgroundColor: "#fe319e", borderColor: "black", borderWidth: "2px" }}>
                  {caps(t("menu"))}
                </Button>
              </NavLink>
            </Nav.Item>
            {/*             <Nav.Item>
              <NavLink className="nav-link" to="/about">
                <Button style={{ backgroundColor: "#fe319e", borderColor: "black", borderWidth: "2px" }}>
                  {caps(t("about"))}
                </Button>
              </NavLink>
            </Nav.Item> */}
            <Nav.Item>
              <NavLink className="nav-link" to="/contact">
                <Button style={{ backgroundColor: "#fe319e", borderColor: "black", borderWidth: "2px" }}>
                  {caps(t("contact"))}
                </Button>
              </NavLink>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
        <Nav>
          <Nav.Item>
            <Dropdown className="justify-content-end " drop="start" align="lg">
              <Dropdown.Toggle variant="light" id="dropdown-basic">
                <h4>
                  <i className="bi bi-globe" />
                </h4>
              </Dropdown.Toggle>
              <Dropdown.Menu style={{ position: "absolute" }}>
                {languages.map((language) => (
                  <Dropdown.Item key={language.code} onClick={() => changeLanguage(language.code)}>
                    <span className={`flag-icon flag-icon-${language.countryCode} mx-2`}></span>{" "}
                    {language.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Nav.Item>
        </Nav>
      </Navbar>

      {/* Navbar for mobile screens */}
      <Navbar className="px-3 d-md-none" bg="light" variant="light">
        <Button variant="outline-dark" onClick={toggleOffcanvas}>
          <i className="bi bi-list" />
        </Button>
        <Navbar.Brand className={"mx-auto "} as={NavLink} to="/">
          <Image src="logo.png" />
        </Navbar.Brand>
        <Nav className="left-0">
          <Nav.Item>
            <Dropdown className="justify-content-end " drop="start" align="lg">
              <Dropdown.Toggle variant="light" id="dropdown-basic">
                <h4>
                  <i className="bi bi-globe" />
                </h4>
              </Dropdown.Toggle>
              <Dropdown.Menu style={{ position: "absolute" }}>
                {languages.map((language) => (
                  <Dropdown.Item key={language.code} onClick={() => changeLanguage(language.code)}>
                    <span className={`flag-icon flag-icon-${language.countryCode} mx-2`}></span>{" "}
                    {language.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Nav.Item>
        </Nav>
      </Navbar>

      {/* Offcanvas menu for mobile screens */}
      <Offcanvas style={{ width: "20em" }} show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <h2>La pizza factory</h2>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <NavLink className="nav-link" to="/" onClick={handleClose}>
            <h5>{caps(t("home"))}</h5>
          </NavLink>
          <hr style={{ color: "black" }} />
          <NavLink className="nav-link" to="/menu" onClick={handleClose}>
            <h5>{caps(t("menu"))}</h5>
          </NavLink>
          {/*           <hr style={{ color: "black" }} />
          <NavLink className="nav-link" to="/about" onClick={handleClose}>
            <h5>{caps(t("about"))}</h5>
          </NavLink> */}
          <hr style={{ color: "black" }} />
          <NavLink className="nav-link" to="/contact" onClick={handleClose}>
            <h5>{caps(t("contact"))}</h5>
          </NavLink>
          <hr style={{ color: "black" }} />
          <p className="text-center text-muted h-auto">Version: {packageJson.version}</p>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default LpfNavbar;
