import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Container, Button, Modal } from "react-bootstrap";

import caps from "../utils/capital";

const OrderButton = () => {
  const { t } = useTranslation();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Container className="order-button-holder">
        <Button style={{ backgroundColor: "#fe319e", borderColor: "#fe319e" }} onClick={handleShow}>
          {caps(t("order_now"))}
        </Button>
      </Container>
      <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>{t("deliveryMode")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t("deliveryModeText")}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {caps(t("cancel"))}
          </Button>
          <Button
            variant="success"
            onClick={handleClose}
            target="_blank"
            rel="noopener noreferrer"
            href="https://lapizzafactory.orderyoyo.com/"
          >
            {t("takeaway")}
          </Button>
          <Button
            variant="primary"
            onClick={handleClose}
            target="_blank"
            rel="noopener noreferrer"
            href="https://wolt.com/en/dnk/esbjerg/restaurant/la-pizza-factory/"
          >
            Wolt
          </Button>
          {/* <Button
            style={{ backgroundColor: "#ff8000", borderColor: "#ff8000" }}
            onClick={handleClose}
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.just-eat.dk/en/menu/la-pizza-factory"
          >
            JustEat
          </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default OrderButton;
